<template>
  <div class="charity-wrapper">
    <v-list-item class="header">
      <v-list-item-avatar color="primary" size="28">
        <v-icon color="white" size="24">mdi-plus</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <div class="text-lg font-weight-medium">Add Charity</div>
      </v-list-item-content>
    </v-list-item>
    <div class="row px-4">
      <template v-if="charities">
        <div class="col-6" v-for="(charity, index) in charities" :key="index">
          <div class="charity-box" v-ripple>
            <div
              class="charity-logo text-center pa-1"
              @click="selectCharity(charity)">
              <v-img
                height="68px"
                contain
                lazy-src="@/assets/images/loading-rolling.svg"
                :src="charity.logo.default"
                :title="charity.name"
                :aspect-ratio="2"
                v-if="charity.logo && charity.logo.default"></v-img>
              <div
                class="text-primary d-flex w-[100%] h-[100px] items-center justify-center"
                v-else>
                <h1 class="h-[68px]">
                  {{
                    charity.name
                      .split(' ')
                      .map((x) => x.substring(0, 1))
                      .join('')
                      .toUpperCase()
                  }}
                </h1>
              </div>
            </div>
            <div
              class="item-label d-flex justify-space-between flex-nowrap"
              :title="charity.name">
              <div @click="selectCharity(charity)" class="flex-grow-1">
                {{ charity.name }}
              </div>
              <v-btn
                icon
                x-small
                v-if="charity.settings.showSummary && charity.summary"
                @click="
                  () => {
                    sumCharity = charity
                    infoDialog = true
                  }
                ">
                <v-icon>mdi-information-outline</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="row" v-if="$slots.footer">
      <div class="col-12 pb-6">
        <slot name="footer"></slot>
      </div>
    </div>

    <dialog-small
      :title="sumCharity.name"
      :open.sync="infoDialog"
      v-if="sumCharity">
      <div class="pa-4">
        <div v-html="sumCharity.summary"></div>
      </div>
    </dialog-small>
    <v-overlay :value="loading" absolute>
      <v-progress-circular
        class="mx-auto mb-4 d-block"
        indeterminate
        size="32"></v-progress-circular>
      <div class="text-center font-weight-medium white--text">
        Adding Charity
      </div>
    </v-overlay>
  </div>
</template>

<script>
import DialogSmall from '../wrapper/DialogSmall'
export default {
  name: 'CharitiesFeatured',
  components: { DialogSmall },
  data() {
    return {
      sumCharity: null,
      loading: false,
      infoDialog: false
    }
  },
  async mounted() {
    await this.$store.dispatch('app/load_charities')
  },
  methods: {
    async selectCharity(charity) {
      this.loading = true
      await this.$store
        .dispatch('account/save_entity_charity', {
          abn: Number(charity.abn),
          name: charity.name.trim(),
          type: 'charity',
        })
        .then((id) => {
          this.$emit('add', id)
        })

      this.loading = false
    }
  },

  computed: {
    charities() {
      let featured = this.$store.getters['app/charities_list_featured'](
        this.userState
      )
      return featured ? featured.slice(0, 6) : null
    },
    userState() {
      return (
        this.$store.getters['account/user_address'].value.state || 'default'
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.w-title > :first-child {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.charity-wrapper {
  position: relative;
  border: 2px solid #d6dce7;
  border-radius: 6px;
  margin: 0;
  justify-content: center !important;
  .header {
    min-height: 60px;
  }
}

.charity-box {
  border: 2px solid #d6dce7;
  border-radius: 6px;
  background-color: rgba(255, 255, 255);
  cursor: pointer;
  .charity-logo {
    position: relative;
  }
  &:hover .charity-logo:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.05);
  }

  img,
  h1 {
    padding: 0.1rem;
    margin: 6px;
    height: 56px;
    max-width: calc(100% - (0.2rem * 2));
  }

  .item-label {
    width: 100%;
    font-weight: 600;
    color: #000;
    font-size: 0.8rem;
    background-color: #d6dce7;
    padding: 0.5rem;

    & > :first-child {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
