<template>
  <v-card
    outlined
    class="pt-4"
  >
    <div class="px-4">
      <p class="font-weight-medium">
        You have opted to include a charity in your Will.
      </p>
      <p>
        Charities truly appreciate receiving details of bequests and those who
        make them. May we share your contact information, date of birth and
        details of your gift with any charities you include in your Will?
      </p>
      <v-checkbox
        v-model="localValue"
        label="Share my contact information, date of birth and bequest details."
      />
    </div>
    <div
      class="text-center pa-1"
      style="background-color: #f2f2f2; font-size: 14px; color: #555"
    >
      If you change your mind, you can update your
      <strong>Preferences</strong> in the <strong>My Account</strong> menu from
      your dashboard at any time.
    </div>
  </v-card>
</template>

<script>
export default {
  name: "CharityShareData",
  props: {
    value: {
      default: null,
    },
  },
  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("update:value", val)
      },
    },
  },
}
</script>
