<template>
  <div>
    <p>Add each of your pets below and select who you would like to look after them.</p>
    <div class="d-flex align-center py-2">
      <h3>Your Pets</h3>
      <v-spacer />
      <v-btn
        text
        class="bg-grey-20 radius-4"
        @click="open()"
      ><v-icon color="primary" left>mdi-plus-thick</v-icon>Add</v-btn>
    </div>

    <transition name="component-fade" mode="out-in">
      <v-card
        flat
        color="bg-grey-10"
        v-if="entities_pets.length === 0"
        class="py-4"
      >
        <div class="results-empty" key="empty">
          <div>To add a pet click the <strong>'Add'</strong> button on the right</div>
        </div>
        <div class="text-center" v-if="!step.isStepComplete || processing">
          <div class="text-label">No pets?</div>
          <v-btn
            :loading="processing"
            class="bg-white text-capitalize"
            text
            x-large
            @click="skip()"
          >Skip this Step<v-icon right color="primary" size="28">mdi-chevron-right</v-icon></v-btn>
        </div>
      </v-card>
      <div v-else key="list">

        <transition-group name="component-fade" mode="out-in">
          <section-item
            v-for="item in entities_pets"
            class="mb-1"
            :key="item.id"
            @edit="open(item.id)"
            @delete="deletePet(item.id)"
          >

            <div class="font-weight-bold mb-2 text-lg">{{ item.name }} ({{item.petType}})</div>
            <div><strong>To:</strong> {{ $store.getters['account/entity_person'](item.guardian).name }}</div>
          </section-item>
        </transition-group>
      </div>
    </transition>

    <dialog-full :open.sync="dialogOpen" :title="dialogTitle" headline="Step 3" path="/will/pets">
      <component
        v-if="openForm"
        :is="`will-form-${openForm}`"
        @close="close"
        @next="close"
        :id="petId"
        close-label="Cancel"
      />
    </dialog-full>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import DialogFull from '../../../components/wrapper/DialogFull'
import SectionItem from '../../../components/wrapper/SectionItem'
import WillFormPet from '../forms/Pet'
import mixins from './mixins'

export default {
  name: 'WillPets',
  mixins: [mixins],
  components: { SectionItem, WillFormPet, DialogFull },
  data: () => ({
    openForm: null,
    petId: null,
    processing: false,
  }),
  methods: {
    close () {
      this.openForm = null
      this.petId = null
    },
    async skip() {
      this.processing = true
      await this.$store.dispatch('account/update_will_progress', ['pets'])
      this.$emit('next')
      this.processing = false
    },
    open (id = null) {
      if (this.accessStatus === 'expired') {
        this.$emit('accessDenied')
        return
      }
      this.petId = id
      this.openForm = 'pet'
    },
    async deletePet(id) {
      if (this.accessStatus === 'expired') {
        this.$emit('accessDenied')
        return
      }
      await this.$store.dispatch('account/delete_entity', id)
    }
  },
  computed: {
    ...mapGetters('account', ['entities_pets']),
    dialogOpen: {
      get () {
        return Boolean(this.openForm)
      },
      set (val) {
        if (!val) {
          this.close()
        }
      }
    },
    dialogTitle () {
      return this.petId ? 'Edit Pet' : 'Add Pet'
    }
  }
}
</script>

<style lang="scss">
</style>
