<template>
  <div>
    <p>
      An Executor is a person or professional service provider you appoint in your Will to administer your estate after your death.
    </p>
    <faqs :faqs="['executorDuties', 'executorOverseas']" class="mb-4" />

    <div class="d-flex align-center py-2">
      <h3>Your Executors</h3>
      <v-spacer />
    </div>
    <div class="text-center" v-if="!step.isStepComplete">
      <v-card flat color="bg-grey-10" class="px-4 pb-4">
        <div class="text-no-result">
          You must select at least one Executor to continue
        </div>
        <v-btn
          text
          class="bg-white text-capitalize"
          @click="open('executors-get-started')"
          x-large
          >Select your Executors<v-icon right color="primary" size="28"
            >mdi-chevron-right</v-icon
          ></v-btn
        >
      </v-card>
    </div>
    <div v-else>
      <section-item @edit="open('executors-get-started')" class="mb-1">
        <div class="text-label mb-2">Primary Executors</div>
        <ul v-if="will_executors.value.primary.length" class="mt-2">
          <li
            v-for="item in will_executors.value.primary"
            :key="item"
            class="mb-1">
            {{ executors[item].name }}
            <chip-required
              v-if="
                item !== 'ausUnity' &&
                (!executors[item].address ||
                  !executors[item].address.trim().length)
              "
              text="Address required"
              @click="
                () => {
                  id = item
                  openEdit = true
                }
              " />
          </li>
        </ul>
        <div v-else>
          <chip-required />
          <div class="text-no-result text-left">
            Click 'Edit' on the right to add at least 1 primary Executor
          </div>
        </div>
      </section-item>
      <section-item
        @edit="open('executors-backup')"
        v-if="
          will_executors.value.primary &&
          !will_executors.value.primary.includes('ausUnity')
        ">
        <div class="text-label mb-2">Backup Executors</div>
        <ol v-if="will_executors.value.backup.length">
          <li
            v-for="item in will_executors.value.backup"
            :key="item"
            class="mb-1">
            {{ executors[item].name }}
            <chip-required
              v-if="
                item !== 'ausUnity' &&
                (!executors[item].address ||
                  !executors[item].address.trim().length)
              "
              text="Address required"
              @click="
                () => {
                  id = item
                  openEdit = true
                }
              " />
          </li>
        </ol>
        <div v-else class="text-no-result text-left">
          No backup Executors selected
        </div>
      </section-item>
    </div>
    <drawer title="Edit Person" :open.sync="openEdit">
      <div class="pa-4">
        <form-person
          v-if="openEdit"
          :id="id"
          @close="
            () => {
              openEdit = false
              id = null
            }
          "
          @next="
            () => {
              openEdit = false
              id = null
            }
          "
          :editable="{ address: true }" />
      </div>
    </drawer>
    <dialog-full
      :open.sync="dialogOpen"
      headline="Step 4"
      title="Executors"
      path="/will/executors">
      <component
        v-if="openForm"
        :is="`will-form-${openForm}`"
        @close="close"
        @next="close" />
    </dialog-full>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import FormPerson from '../../../components/form/Person'
import ChipRequired from '../../../components/ui/ChipRequired'
import Faqs from '../../../components/ui/Faqs'
import NotificationBox from '../../../components/ui/NotificationBox'
import DialogFull from '../../../components/wrapper/DialogFull'
import Drawer from '../../../components/wrapper/Drawer'
import ItemPerson from '../../../components/wrapper/ItemPerson'
import SectionItem from '../../../components/wrapper/SectionItem'
import WillFormExecutorsBackup from '../forms/ExecutorsBackup'
import WillFormExecutorsGetStarted from '../forms/ExecutorsGetStarted'
import WillFormExecutorsPrimary from '../forms/ExecutorsPrimary'
import mixins from './mixins'

export default {
  name: 'WillExecutors',
  mixins: [mixins],
  components: {
    NotificationBox,
    ChipRequired,
    SectionItem,
    Faqs,
    WillFormExecutorsPrimary,
    WillFormExecutorsBackup,
    WillFormExecutorsGetStarted,
    DialogFull,
    Drawer,
    ItemPerson,
    FormPerson
  },
  data: () => ({
    openForm: null,
    openEdit: false,
    id: null
  }),
  methods: {
    close() {
      this.openForm = null
    },
    open(key) {
      if (this.accessStatus === 'expired') {
        this.$emit('accessDenied')
        return
      }

      this.openForm = key
    }
  },
  computed: {
    ...mapGetters('account', ['will_executors']),
    dialogOpen: {
      get() {
        return Boolean(this.openForm)
      },
      set(val) {
        if (!val) {
          this.close()
        }
      }
    },
    executors() {
      let executors = {}
      this.will_executors.value.primary.forEach((v) => {
        executors[v] = this.$store.getters['account/entity'](v)
      })
      this.will_executors.value.backup.forEach((v) => {
        executors[v] = this.$store.getters['account/entity'](v)
      })

      return executors
    }
  }
}
</script>

<style lang="scss"></style>
