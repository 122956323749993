<template>
  <div>
    <div v-if="local">
      <step-progress
        :current="window"
        :total="3"
        class="mb-6"
      />
      <div class="divider mb-6 opacity-80"></div>
      <v-window
        touchless
        v-model="window"
      >
        <v-window-item :value="1">
          <h2 class="mb-4">Who would you like to leave this gift to?</h2>
          <p>
            You can select a person or a charity to give a gift to. Once
            selected, click the Next button to continue.
          </p>
          <transition
            name="component-fade"
            mode="out-in"
          >
            <div
              v-if="localFormatted.recipient"
              :key="localFormatted.recipient"
            >
              <item-person
                :id="localFormatted.recipient"
                class="mb-4"
              />
              <btn-add-large
                @click="removeRecipient()"
                label="Change Recipient"
                icon="mdi-sync"
                :disabled="disable || processing"
              />
              <charity-share-data
                v-if="showCharityOptOut"
                :value.sync="shareData"
              />
            </div>
            <div
              v-else
              key="select"
            >
              <btn-add-large
                @click="openDrawerPerson = true"
                label="Add Person"
                :disabled="disable || processing"
              />
              <template v-if="referrer_beneficiary_doc">
                <btn-add-large
                  :label="`Add ${referrer_beneficiary_doc.name}`"
                  subtitle="Featured Charity"
                  class="mt-2"
                  :img="
                    referrer_beneficiary_doc.logo
                      ? referrer_beneficiary_doc.logo.icon
                      : null
                  "
                  @click="addCharity(referrer_beneficiary_doc)"
                  :disabled="disable || processing"
                />
                <btn-add-large
                  @click="openDrawerCharity = true"
                  label="Add Charity"
                  class="mt-2"
                  :disabled="disable || processing"
                />
              </template>
              <template v-else>
                <charities-featured
                  class="mt-4"
                  @add="addRecipient"
                >
                  <template v-slot:footer>
                    <div class="text-center">
                      <v-btn
                        text
                        class="bg-grey"
                        @click="openDrawerCharity = true"
                      >
                        <v-icon
                          left
                          color="primary"
                          >mdi-magnify</v-icon
                        >
                        View more
                      </v-btn>
                    </div>
                  </template>
                </charities-featured>
              </template>
            </div>
          </transition>

          <v-input
            :error-messages="
              showErrors.recipient && errorMessages.recipient
                ? errorMessages.recipient
                : null
            "
          />
          <div class="d-flex align-center">
            <v-btn
              v-bind="backButton"
              @click="close"
              ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
            >
            <v-spacer />
            <v-btn
              v-bind="isValidRecipient ? btnActive : btnInactive"
              depressed
              @click="
                isValidRecipient
                  ? nextStep()
                  : showErrorsSwitch(true, 'recipient')
              "
              >Next</v-btn
            >
          </div>
        </v-window-item>
        <v-window-item :value="2">
          <h2 class="mb-4 line-height-md">
            What type of gift would you like to leave {{ recipientName }}?
          </h2>
          <item-select
            v-for="(item, index) in giftTypeOptions"
            :key="item.key"
            :value="item.key === localFormatted.giftType"
            @select="local.giftType = item.key"
            :title="item.label"
            :text="item.text"
            :class="{ 'mb-4': index + 1 < giftTypeOptions.length }"
          />
          <v-input
            :error-messages="
              showErrors.giftType && errorMessages.giftType
                ? errorMessages.giftType
                : null
            "
          />
          <div class="d-flex align-center">
            <v-btn
              v-bind="backButton"
              @click="prevStep()"
              ><v-icon left>mdi-chevron-left</v-icon>Back</v-btn
            >
            <v-spacer />
            <v-btn
              v-bind="isValidType ? btnActive : btnInactive"
              depressed
              @click="
                isValidType ? nextStep() : showErrorsSwitch(true, 'giftType')
              "
              >Next</v-btn
            >
          </div>
        </v-window-item>
        <v-window-item :value="3">
          <!-- money -->
          <div v-if="localFormatted.giftType === 'money'">
            <h2 class="mb-4 line-height-md">
              How much would you like to gift {{ recipientName }}?
            </h2>
            <p>
              Enter the amount you would like to gift in Australian Dollars
              (AUD).
            </p>
            <money-field
              label="Amount (AUD)"
              v-model="local.itemMoney"
              :error-messages="
                showErrors.itemMoney && errorMessages.itemMoney
                  ? errorMessages.itemMoney
                  : null
              "
              @blur="showErrorsSwitch(true, 'itemMoney')"
              @keydown="showErrorsSwitch(true, 'itemMoney')"
            />
          </div>
          <!-- personal item -->
          <div v-if="localFormatted.giftType === 'possession'">
            <h2 class="mb-4 line-height-md">
              What would you like to gift {{ recipientName }}?
            </h2>
            <notification-box
              type="info"
              class="mb-4"
              :flat="true"
            >
              <strong>Note:</strong> Be specific when describing the item, so
              that it’s clear to the Executor and the beneficiary exactly what
              the gift is. Avoid leaving any messages here.
            </notification-box>
            <div class="mb-2">
              I would like to leave {{ recipientName }} my...
            </div>
            <v-text-field
              ref="itemPossession"
              v-bind="textField"
              placeholder="Enter gift here"
              v-model="local.itemPossession"
              :error-messages="
                showErrors.itemPossession && errorMessages.itemPossession
                  ? errorMessages.itemPossession
                  : null
              "
              @blur="showErrorsSwitch(true, 'itemPossession')"
              @input="showErrorsSwitch(true, 'itemPossession')"
            ></v-text-field>
          </div>

          <!-- message -->
          <div v-if="messageExists">
            <h2 class="mb-4">Leave a message</h2>
            <v-textarea
              v-bind="textAreaField"
              placeholder="Enter message"
              v-model="local.message"
              :error-messages="
                showErrors.message && errorMessages.message
                  ? errorMessages.message
                  : null
              "
            />
          </div>

          <div class="d-flex align-center">
            <v-btn
              v-bind="backButton"
              @click="prevStep()"
              ><v-icon left>mdi-chevron-left</v-icon>Back</v-btn
            >
            <v-spacer />
            <v-btn
              v-bind="buttonType"
              depressed
              @click="submit"
              >Save</v-btn
            >
          </div>
        </v-window-item>
      </v-window>

      <drawer
        :open.sync="openDrawerPerson"
        title="Add Recipient"
      >
        <drawer-person-select
          v-if="openDrawerPerson"
          @select="addRecipient"
          :exclude="[localFormatted.recipient]"
        />
      </drawer>
      <charity-select
        :open.sync="openDrawerCharity"
        title="Select Charity"
        @add="addRecipient"
      />
    </div>
  </div>
</template>

<script>
import { cloneDeep, isEqual } from "lodash"
import { mapGetters } from "vuex"

import CharitiesFeatured from "../../../components/charities/CharitiesFeatured"
import CharitySelect from "../../../components/charities/CharitySelect"
import CharityShareData from "../../../components/charities/CharityShareData"
import BtnAddLarge from "../../../components/ui/BtnAddLarge"
import ItemSelect from "../../../components/ui/ItemSelect"
import MoneyField from "../../../components/ui/MoneyField"
import NotificationBox from "../../../components/ui/NotificationBox"
import StepProgress from "../../../components/ui/StepProgress"
import Drawer from "../../../components/wrapper/Drawer"
import DrawerPersonSelect from "../../../components/wrapper/DrawerPersonSelect"
import ItemPerson from "../../../components/wrapper/ItemPerson"

export default {
  name: "WillFormGift",
  components: {
    CharityShareData,
    CharitiesFeatured,
    CharitySelect,
    MoneyField,
    NotificationBox,
    ItemSelect,
    StepProgress,
    BtnAddLarge,
    Drawer,
    ItemPerson,
    DrawerPersonSelect,
  },
  props: {
    closeLabel: {
      default: "close",
    },
    id: {
      default: null,
    },
  },
  data: () => ({
    local: null,
    shareData: true,
    showErrors: {
      itemMoney: false,
      itemPossession: false,
      giftType: false,
      recipient: false,
      message: true,
    },
    processing: false,
    disable: false,
    openDrawerPerson: false,
    openDrawerCharity: false,
    window: 1,
    messageExists: false,
    moneyFormat: {
      decimal: ".",
      thousands: ",",
      precision: 0,
      prefix: "$",
      masked: false,
    },
    giftTypeOptions: [],
  }),
  mounted() {
    this.giftTypeOptions =
      this.$store.getters["account/list_group"]("giftTypes")
    this.messageExists = Boolean(this.entity_gift.message.trim())
    const local = cloneDeep(this.entity_gift)
    local.itemMoney = local.giftType === "money" ? local.item : "$0"
    local.itemPossession =
      local.giftType === "possession"
        ? local.item.trim().replace(/\s\s+/g, " ")
        : ""
    this.local = local
  },
  methods: {
    async submit() {
      this.processing = true
      if (this.isValid) {
        if (this.isChanged) {
          await this.save()
        }
        if (this.showCharityOptOut) {
          await this.$store.dispatch("account/save_user_data_deep", {
            meta: {
              shareWithCharities: this.shareData,
              shareWithCharitiesIncludesDob: true,
            },
          })
        }
        setTimeout(() => {
          this.next()
        }, 500)
        return
      }

      this.showErrorsSwitch()
      this.processing = false
    },
    nextStep() {
      this.window++
    },
    prevStep() {
      this.window--
    },
    async save() {
      await this.$store.dispatch("account/save_entity", {
        id: this.id,
        entity: this.localFormatted,
        type: "gift",
      })
      await this.$store.dispatch("account/update_will_progress", ["gifts"])
    },
    close() {
      this.$emit("close")
    },
    next() {
      this.$emit("next")
    },
    showErrorsSwitch(show = true, field = null) {
      if (field) {
        this.showErrors[field] = show
        return
      }

      Object.keys(this.showErrors).forEach((key) => {
        this.showErrors[key] = show
      })
    },
    addRecipient(id) {
      this.showErrorsSwitch(false, "recipient")
      this.local.recipient = id
      this.openDrawerPerson = false
      this.openDrawerCharity = false
    },
    async addCharity(charity) {
      this.disable = true
      let id = await this.$store.dispatch("account/save_entity_charity", {
        abn: Number(charity.abn),
        name: charity.name.trim(),
        type: "charity",
      })

      this.addRecipient(id)
      this.disable = false
    },
    removeRecipient() {
      this.showErrorsSwitch(false, "recipient")
      this.local.recipient = null
    },
  },
  computed: {
    ...mapGetters("app", ["referrer_beneficiary_doc"]),
    ...mapGetters("account", ["user_meta_data"]),
    recipientName() {
      return this.localFormatted.recipient
        ? this.$store.getters["account/entity"](this.localFormatted.recipient)
            .name
        : ""
    },
    showCharityOptOut() {
      if (
        this.localFormatted.recipient &&
        typeof this.user_meta_data.shareWithCharities === "undefined"
      ) {
        const recipient = this.$store.getters["account/entity"](
          this.localFormatted.recipient
        )
        return recipient.type === "charity"
      }
      return false
    },
    entity_gift() {
      return this.$store.getters["account/entity_gift"](this.id)
    },
    buttonType() {
      if (this.processing) {
        return this.btnProcessing
      }

      if (this.isValid) {
        return this.btnActive
      }

      return this.btnInactive
    },
    errorMessages() {
      const msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })

      if (!this.localFormatted.recipient) {
        msgs.recipient = "Required"
      }

      if (!this.localFormatted.giftType) {
        msgs.giftType = "Required"
      }

      if (this.localFormatted.giftType === "money") {
        let amount = Number(this.localFormatted.item.replace(/[^0-9.-]+/g, ""))
        if (amount === 0) {
          msgs.itemMoney = "Can't leave zero dollars"
        }
        if (amount > 100000000) {
          msgs.itemMoney = "Maximum amount exceeded"
        }
        if (!this.localFormatted.item) {
          msgs.itemMoney = "Required"
        }
      }

      if (this.localFormatted.giftType === "possession") {
        if (!this.localFormatted.item) {
          msgs.itemPossession = "Required"
        }
        if (this.localFormatted.item.length > 120) {
          msgs.itemPossession = "Maximum of 120 characters exceeded"
        }
      }

      if (this.localFormatted.message.length > 2000) {
        msgs.message = "Maximum of 2000 characters exceeded"
      }

      return msgs
    },
    isValid() {
      return Object.values(this.errorMessages).every((val) => val === null)
    },
    isValidRecipient() {
      return !this.errorMessages.recipient
    },
    isValidType() {
      return !this.errorMessages.giftType
    },
    isChanged() {
      return !isEqual(this.localFormatted, this.entity_gift)
    },
    localFormatted() {
      const formatted = {
        ...this.entity_gift,
        item: "",
        giftType: this.giftTypeOptions
          .map((o) => o.key)
          .includes(this.local?.giftType)
          ? this.local.giftType
          : null,
        message: this.local?.message.trim() || "",
        recipient: this.local?.recipient || null,
      }

      if (formatted.giftType === "money") {
        formatted.item = this.local.itemMoney.trim() || "$0"
      }
      if (formatted.giftType === "possession") {
        formatted.item =
          this.local.itemPossession.trim().replace(/\s\s+/g, " ") || ""
      }

      return formatted
    },
  },
  watch: {
    window() {
      document.querySelectorAll(".dialog-full-container").forEach((el) => {
        el.scrollTo(0, 0)
      })
    },
  },
}
</script>

<style lang="scss"></style>
