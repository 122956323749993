<template>
  <div>
    <v-window touchless v-model="window">
      <v-window-item
        v-for="(item, index) in windows"
        :key="item"
        :value="index + 1">
        <executors-primary
          submitLabel="Save"
          :closeLabel="index === 0 ? 'Close' : 'Back'"
          v-if="item === 'primary' && window === 1"
          @close="
            () => {
              index === 0 ? close() : prevStep()
            }
          "
          @next="
            () => {
              index + 1 === windows.length ? close() : nextStep()
            }
          "
        />
        <executors-backup
          submitLabel="Save"
          :closeLabel="index === 0 ? 'Close' : 'Back'"
          v-if="item === 'backup' && window === 2"
          @close="
            () => {
              index === 0 ? close() : prevStep()
            }
          "
          @next="
            () => {
              index + 1 === windows.length ? close() : nextStep()
            }
          "
        />
      </v-window-item>
    </v-window>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

import ExecutorsBackup from './ExecutorsBackup'
import ExecutorsPrimary from './ExecutorsPrimary'

export default {
  name: 'ExecutorsGetStarted',
  components: { ExecutorsBackup, ExecutorsPrimary },
  data: () => ({
    window: 1
  }),
  methods: {
    nextStep() {
      this.window++
    },
    prevStep() {
      this.window--
    },
    close() {
      this.$emit('close')
    },
    next() {
      this.$emit('next')
    }
  },
  computed: {
    ...mapGetters('account', ['will_executors']),
    windows() {
      const primary = this.will_executors.value.primary || []
      if (primary.includes('ausUnity')) return ['primary']
      else return ['primary', 'backup']
    }
  },
  watch: {
    window() {
      document.querySelectorAll('.dialog-full-container').forEach((el) => {el.scrollTo(0, 0)})
    }
  }
}
</script>
