<template>
  <div
    v-if="local"
    ref="top"
  >
    <transition
      name="component-fade"
      mode="out-in"
    >
      <v-window
        touchless
        v-model="window"
      >
        <v-window-item
          v-for="(beneficiary, index) in local"
          :key="index"
          :value="index"
        >
          <h2 class="mb-4">
            Select backups for {{ beneficiaryNames[beneficiary.id] }}
          </h2>
          <p>
            If a beneficiary predeceases you or is otherwise unable to receive
            an allocation from your estate, we recommend you appoint one or more
            backup beneficiaries.
          </p>
          <p>
            Choose how you wish the inheritance of
            {{ beneficiaryNames[beneficiary.id] }} to be distributed if they are
            unable to receive an allocation from your estate.
            <strong>Note:</strong> If you nominate multiple backup
            beneficiaries, they will receive the primary beneficiary's share of
            your estate in equal proportions.
          </p>
          <h3 class="mb-4 mt-6 line-height-md">
            If {{ beneficiaryNames[beneficiary.id] }} is unable to receive their
            share of your estate, who would you like it to be left to?
          </h3>
          <item-select
            :value="beneficiary.data.backupOption === 'theirChildren'"
            title="Go directly to their children"
            class="mb-2"
            @select="beneficiary.data.backupOption = 'theirChildren'"
          >
            <transition name="slide">
              <div
                class="px-4 pb-4 text-sm"
                v-if="beneficiary.data.backupOption === 'theirChildren'"
              >
                <div class="bg-grey-10 pa-2 radius-4">
                  The children of {{ beneficiaryNames[beneficiary.id] }}
                </div>
              </div>
            </transition>
          </item-select>
          <item-select
            :value="beneficiary.data.backupOption === 'myBeneficiaries'"
            title="Divide among my surviving beneficiaries"
            class="mb-2"
            @select="beneficiary.data.backupOption = 'myBeneficiaries'"
            v-if="Object.keys(will_estate.value).length > 1"
          >
            <transition name="slide">
              <div
                class="px-4 pb-4 text-sm"
                v-if="beneficiary.data.backupOption === 'myBeneficiaries'"
              >
                <div class="bg-grey-10 pa-2 radius-4">
                  Back to my estate to be divided amongst my surviving
                  beneficiaries (eg.
                  {{ Object.values(beneficiaryNames).join(", ") }} ).
                </div>
              </div>
            </transition>
          </item-select>
          <item-select
            :value="beneficiary.data.backupOption === 'newBeneficiaries'"
            title="Select new beneficiaries"
            class="mb-2"
            @select="
              () => {
                beneficiary.data.backupOption = 'newBeneficiaries'
                setScrollPos()
              }
            "
          >
            <transition name="slide">
              <div
                v-if="beneficiary.data.backupOption === 'newBeneficiaries'"
                class="pa-2"
              >
                <div
                  v-if="
                    !beneficiary.data.backupEntities ||
                    beneficiary.data.backupEntities.length === 0
                  "
                  class="text-no-result"
                  key="empty"
                >
                  - Add at least one beneficiary -
                </div>
                <div
                  key="list"
                  v-else
                >
                  <transition-group
                    name="component-fade"
                    mode="out-in"
                  >
                    <item-person
                      v-for="(item, i) in beneficiary.data.backupEntities"
                      :key="`entity-${i}`"
                      class="mb-4"
                      :id="item"
                      @remove="removeBackupBeneficiary(beneficiary.id, i)"
                    >
                    </item-person>
                  </transition-group>
                </div>

                <div class="mb-2">
                  <btn-add-large
                    label="Add person"
                    class="mb-2"
                    @click="openDrawerPerson = true"
                  />
                  <charities-featured
                    class="mt-4"
                    @add="(id) => addBeneficiary(beneficiary.id, id)"
                  >
                    <template v-slot:footer>
                      <div class="text-center">
                        <v-btn
                          text
                          class="bg-grey"
                          @click="openDrawerCharity = true"
                        >
                          <v-icon
                            left
                            color="primary"
                            >mdi-magnify</v-icon
                          >
                          View more
                        </v-btn>
                      </div>
                    </template>
                  </charities-featured>
                </div>
              </div>
            </transition>
            <drawer
              :open.sync="openDrawerPerson"
              title="Add Beneficiary"
            >
              <drawer-person-select
                v-if="openDrawerPerson"
                @select="(id) => addBeneficiary(beneficiary.id, id)"
                :exclude="[beneficiary.id, ...beneficiary.data.backupEntities]"
              />
            </drawer>
            <charity-select
              :open.sync="openDrawerCharity"
              title="Select Charity"
              @add="(id) => addBeneficiary(beneficiary.id, id)"
            />
          </item-select>
          <v-input
            :error-messages="
              showErrors[window] && errorMessages[window].form
                ? errorMessages[window].form
                : null
            "
          />
          <transition
            name="component-fade"
            mode="out-in"
            v-if="beneficiary.data.backupOption === 'newBeneficiaries'"
          >
            <div class="my-4">
              <charity-share-data
                v-if="showCharityOptOut"
                :value.sync="shareData"
              />
            </div>
          </transition>
          <div class="d-flex align-center">
            <v-btn
              text
              @click="back"
              >Back</v-btn
            >
            <v-spacer />
            <v-btn
              v-bind="buttonType"
              depressed
              @click="submit(index)"
              >Save</v-btn
            >
          </div>
        </v-window-item>
      </v-window>
    </transition>
  </div>
</template>
<script>
import { cloneDeep, isEmpty, isEqual } from "lodash"
import { mapGetters } from "vuex"

import CharitiesFeatured from "../../../components/charities/CharitiesFeatured"
import CharitySelect from "../../../components/charities/CharitySelect"
import CharityShareData from "../../../components/charities/CharityShareData"
import BtnAddLarge from "../../../components/ui/BtnAddLarge"
import ItemSelect from "../../../components/ui/ItemSelect"
import Drawer from "../../../components/wrapper/Drawer"
import DrawerPersonSelect from "../../../components/wrapper/DrawerPersonSelect"
import ItemPerson from "../../../components/wrapper/ItemPerson"
export default {
  name: "WillFormEstateBackup",
  components: {
    CharitySelect,
    CharitiesFeatured,
    CharityShareData,
    BtnAddLarge,
    DrawerPersonSelect,
    Drawer,
    ItemPerson,
    ItemSelect,
  },
  props: {
    closeLabel: {
      default: "close",
    },
  },
  data: () => ({
    local: null,
    window: 0,
    shareData: true,
    showErrors: {},
    processing: false,
    openDrawerPerson: false,
    openDrawerCharity: false,
  }),
  mounted() {
    let estate = cloneDeep(this.will_estate.value)
    this.local = Object.keys(estate)
      .map((k) => {
        return {
          id: k,
          data: estate[k],
        }
      })
      .sort((a, b) => {
        return b.data.share - a.data.share
      })
      .filter((x) => {
        const entity = this.$store.getters["account/entity"](x.id)
        return entity.type === "person"
      })
  },
  methods: {
    async submit(index) {
      this.processing = true
      if (this.isValid) {
        if (this.isChanged) {
          await this.save()
        }
        if (this.showCharityOptOut) {
          await this.$store.dispatch("account/save_user_data_deep", {
            meta: {
              shareWithCharities: this.shareData,
              shareWithCharitiesIncludesDob: true,
            },
          })
        }
        setTimeout(() => {
          this.processing = false
          this.next(index)
        }, 500)
        return
      }

      this.showErrorsSwitch()
      this.processing = false
    },
    async save() {
      await this.$store.dispatch("account/save_user_data", {
        beneficiaries: this.localFormatted,
      })
      await this.$store.dispatch("account/update_will_progress", ["estate"])
    },
    close() {
      this.$emit("close")
    },
    back() {
      if (this.window > 0) this.window--
      else this.$emit("close", "estate")
    },
    next(index) {
      if (index + 1 === this.local.length) {
        this.$emit("close")
        return
      }
      this.window++
    },
    setScrollPos() {
      setTimeout(() => {
        let pos = this.$refs.top.getBoundingClientRect().top + 400
        this.$emit("top", pos)
      }, 500)
    },
    showErrorsSwitch(show = true, field = null) {
      if (isEmpty(this.showErrors[this.window])) {
        this.showErrors[this.window] = { form: false }
      }
      if (field) {
        this.showErrors[this.window][field] = show
        return
      }

      Object.keys(this.showErrors[this.window]).forEach((key) => {
        this.showErrors[this.window][key] = show
      })
    },
    addBeneficiary(key, id) {
      if (
        !this.local.find((x) => x.id === key).data.backupEntities.includes(id)
      ) {
        this.local.find((x) => x.id === key).data.backupEntities.push(id)
      }
      this.openDrawerPerson = false
      this.openDrawerCharity = false
    },
    removeBackupBeneficiary(key, index) {
      this.$delete(
        this.local.find((x) => x.id === key).data.backupEntities,
        index
      )
    },
  },
  watch: {
    window() {
      let pos = this.$refs.top.getBoundingClientRect().top - 100
      this.$emit("top", pos)
    },
  },
  computed: {
    ...mapGetters("account", ["will_estate"]),
    ...mapGetters("account", ["user_meta_data"]),
    beneficiaryNames() {
      return Object.keys(this.will_estate.value).reduce((obj, k) => {
        const beneficiaryEntity = this.$store.getters["account/entity"](k)
        if (beneficiaryEntity.type !== "person") return { ...obj }
        return {
          ...obj,
          [k]: beneficiaryEntity.name,
        }
      }, {})
    },
    buttonType() {
      if (this.processing) {
        return this.btnProcessing
      }

      if (this.isValid) {
        return this.btnActive
      }

      return this.btnInactive
    },
    errorMessages() {
      let msgs = {
        [this.window]: { form: null },
      }

      Object.keys(msgs[this.window]).forEach((field) => {
        msgs[this.window][field] = null
      })
      const selected = this.local[this.window].data
      if (!selected.backupOption) {
        msgs[this.window].form = "Select a backup option"
      }

      if (
        selected.backupOption === "newBeneficiaries" &&
        selected.backupEntities.length === 0
      ) {
        msgs[this.window].form = "Add at least one beneficiary"
      }
      return msgs
    },
    isValid() {
      return this.errorMessages
        ? Object.values(this.errorMessages[this.window]).every(
            (val) => val === null || val.form === null
          )
        : true
    },
    isChanged() {
      return !isEqual(this.localFormatted, this.will_estate.value)
    },
    localFormatted() {
      let estate = {}
      this.local.forEach((o) => {
        estate[o.id] = {
          ...o.data,
        }
      })

      return { ...this.will_estate.value, ...estate }
    },
    showCharityOptOut() {
      const entities = this.local[this.window].data.backupEntities
      if (
        entities.length > 0 &&
        typeof this.user_meta_data.shareWithCharities === "undefined"
      ) {
        let charitySelected = false
        entities.forEach((id) => {
          const entity = this.$store.getters["account/entity"](id)
          if (entity.type === "charity") charitySelected = true
        })
        return charitySelected
      }
      return false
    },
  },
}
</script>
<style>
.item {
  border-color: #d6dce7 !important;
}

.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease();
  -webkit-transition-timing-function: ease();
  -o-transition-timing-function: ease();
  transition-timing-function: ease();
}

.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
