<template>
  <div class="py-8 text-center">
    <div class="font-weight-bold opacity-70 mb-8">
      Scroll up to complete the previous steps before starting this one.
    </div>
    <v-btn v-bind="smallButton" @click="$emit('back')">Go Back</v-btn>
  </div>
</template>

<script>

export default {
  name: 'CompletePrevious',
}
</script>
