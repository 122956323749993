<template>
  <v-text-field
    v-bind="textField"
    v-model="model"
    v-money="format"
    @blur="$emit('blur')"
    @keydown="$emit('keydown')"
    :error-messages="errorMessages"
    :label="label"
  />
</template>

<script>
import { VMoney } from 'v-money'

export default {
  props: {
    value: {
      default: 0
    },
    label: {
      default: null
    },
    errorMessages: {
      default: null,
    },
    format: {
      default: () => {
        return {
          decimal: '.',
          thousands: ',',
          precision: 0,
          prefix: '$',
          masked: false,
        }
      }
    },
  },
  directives: { money: VMoney },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
}
</script>
