<template>
  <div>
    <div v-if="isStarted">
      <section-item @edit="open('about-name')" class="mb-1">
        <div class="text-label">Name</div>
        <div class="mb-2">{{ user_name.displayFull }}</div>
        <div class="text-label">Phone</div>
        <div v-if="user_phone.isComplete">{{ user_phone.value }}</div>
        <div v-else>-</div>
      </section-item>
      <section-item @edit="open('about-dob')" class="mb-1">
        <div class="text-label">Date of Birth</div>
        <div v-if="user_dob.isComplete">{{ user_dob.displayDate }}</div>
        <div v-else><chip-required /></div>
      </section-item>
      <section-item @edit="open('about-address')" class="mb-1">
        <div class="text-label">Address</div>
        <div v-if="user_address.isComplete">
          {{ user_address.displayAddress }}
        </div>
        <div v-else><chip-required /></div>
      </section-item>
      <section-item @edit="open('partner')" class="mb-1">
        <div class="text-label">Relationship Status</div>
        <div v-if="user_partner.isComplete">
          {{ user_partner.displayStatus }}
          <span v-if="user_partner.partnerName" class="mt-2">
            to {{ user_partner.partnerName }}</span
          >
        </div>
        <div v-else><chip-required /></div>
      </section-item>
    </div>
    <div v-else class="text-center">
      <v-card flat color="bg-grey-10" class="px-4 pb-4">
        <div class="text-no-result">
          From here, it can take as little as 15 minutes to complete your Will.
        </div>
        <v-btn v-bind="smallButton" @click="open('about-get-started')"
          >Start your Will</v-btn
        >
      </v-card>
    </div>
    <dialog-full :open.sync="dialogOpen" title="About You" headline="Step 1" :path="gaUrl">
      <component
        v-if="openForm"
        :is="`will-form-${openForm}`"
        :accessStatus="accessStatus"
        @close="close"
        @next="next" />
    </dialog-full>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ChipRequired from '../../../components/ui/ChipRequired'
import Faqs from '../../../components/ui/Faqs'
import DialogFull from '../../../components/wrapper/DialogFull'
import SectionItem from '../../../components/wrapper/SectionItem'
import WillFormAboutAddress from '../forms/AboutAddress'
import WillFormAboutDob from '../forms/AboutDob'
import WillFormAboutGetStarted from '../forms/AboutGetStarted'
import WillFormAboutName from '../forms/AboutName'
import WillFormPartner from '../forms/Partner'
import mixins from './mixins'
export default {
  name: 'WillAbout',
  mixins: [mixins],
  components: {
    ChipRequired,
    Faqs,
    SectionItem,
    WillFormPartner,
    WillFormAboutDob,
    WillFormAboutName,
    WillFormAboutAddress,
    WillFormAboutGetStarted,
    DialogFull
  },
  data: () => ({
    openForm: false,
    gaUrl: '/'
  }),
  methods: {
    close() {
      this.openForm = null
    },
    next() {
      this.openForm = null
    },
    open(key) {
      if (this.accessStatus === 'expired') {
        this.$emit('accessDenied')
        return
      }

      this.gaUrl = '/will/about'
      if (key === 'partner') this.gaUrl = '/will/partner'

      this.openForm = key
    }
  },
  computed: {
    ...mapGetters('account', [
      'user_name',
      'user_phone',
      'user_dob',
      'user_address',
      'user_partner'
    ]),
    isStarted() {
      return this.user_name.isComplete
    },
    isDataComplete() {
      return (
        this.user_name.isComplete &&
        this.user_address.isComplete &&
        this.user_dob.isComplete &&
        this.user_partner.isComplete
      )
    },
    dialogOpen: {
      get() {
        return Boolean(this.openForm)
      },
      set(val) {
        if (!val) {
          this.close()
        }
      }
    }
  }
}
</script>

<style lang="scss"></style>
