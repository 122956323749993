<template>
  <div>
    <v-window touchless v-model="window">
      <v-window-item
        v-for="(window, index) in windows"
        :key="window"
        :value="index + 1">
        <component
          :submitLabel="index + 1 === windows.length ? 'Save' : 'Next'"
          :closeLabel="index === 0 ? 'Close' : 'Back'"
          onSaveBehaviour="next"
          :is="`about-${window}`"
          :accessStatus="accessStatus"
          @close="
            () => {
              index === 0 ? close() : prevStep()
            }
          "
          @next="
            () => {
              index + 1 === windows.length ? close() : nextStep()
            }
          " />
      </v-window-item>
    </v-window>
  </div>
</template>
<script>
import AboutAddress from './AboutAddress'
import AboutDob from './AboutDob'
import AboutName from './AboutName'
import AboutPartner from './Partner'

export default {
  name: 'AboutWindow',
  components: { AboutName, AboutDob, AboutAddress, AboutPartner },
  props: {
    accessStatus: {
      default: null
    }
  },
  data: () => ({
    windows: ['name', 'dob', 'address', 'partner'],
    window: 1
  }),
  methods: {
    nextStep() {
      this.window++
    },
    prevStep() {
      this.window--
    },
    close() {
      this.$emit('close')
    },
    next() {
      this.$emit('next')
    }
  },
  computed: {},
  mounted() {},
  watch: {
    window() {
      document.querySelectorAll('.dialog-full-container').forEach((el) => {el.scrollTo(0, 0)})
    }
  }
}
</script>
