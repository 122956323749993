<template>
  <div>
    <p>
      If you have any children under 18 years old you can nominate guardians to look after them in this section.
    </p>
    <p>
      Add each child under 18 years old below and select 1 or 2 guardians for each of them.
    </p>
    <faqs
      :faqs="['guardiansStepChildren']"
      class="mb-4"
    />
    <div class="d-flex align-center py-2">
      <h3>Your Children</h3>
      <v-spacer />
      <v-btn text class="bg-grey-20 radius-4" @click="open()"
        ><v-icon color="primary" left>mdi-plus-thick</v-icon>Add</v-btn
      >
    </div>

    <transition name="component-fade" mode="out-in">
      <v-card
        flat
        color="bg-grey-10"
        v-if="entities_guardians.length === 0"
        class="py-4"
      >
        <div class="results-empty" key="empty">
          <div>
            To add a child (under 18 years old only) click the <strong>'Add'</strong> button on the right
          </div>
        </div>
        <div class="text-center" v-if="!step.isStepComplete || processing">
          <div class="text-label">No children under 18?</div>
          <v-btn
            :loading="processing"
            class="bg-white text-capitalize"
            text
            x-large
            @click="skip()"
          >Skip this Step<v-icon right color="primary" size="28"
          >mdi-chevron-right</v-icon
          ></v-btn>
        </div>
      </v-card>
      <div v-else key="list">
        <transition-group name="component-fade" mode="out-in">
          <section-item
            v-for="item in entities_guardians"
            class="mb-1"
            :key="item.id"
            @edit="open(item.id)"
            @delete="remove(item.id)"
          >
            <div class="font-weight-bold mb-2 text-lg">
              {{ item.name }}
            </div>
            <div>
              <strong>Guardians:</strong>
              <span v-for="(guardian, index) in item.guardians" :key="guardian">
                {{ $store.getters['account/entity_person'](guardian).name }}
                <template v-if="index < item.guardians.length - 1">&</template>
              </span>
            </div>
          </section-item>
        </transition-group>
      </div>
    </transition>

    <dialog-full :open.sync="dialogOpen" :title="dialogTitle" headline="Step 2" path="/will/children">
      <component
        v-if="openForm"
        :is="`will-form-${openForm}`"
        @close="close"
        @next="close"
        :id.sync="entityId"
        close-label="Cancel"
      />
    </dialog-full>

    <dialog-small
      title="Remove Guardians"
      :open.sync="openRemoveGuardians"
      @close="
        () => {
          this.entityId = null
          this.openRemoveGuardians = false
        }
      "
    >
      <div class="pa-4">
        <p>
          This operation does not delete the child record, only removes the
          guardians from the child.
        </p>
        <div>Do you want to remove the guardians from the child?</div>
      </div>
      <template v-slot:buttons>
        <div class="divider" />
        <v-btn text tile x-large block @click="deleteGuardians(entityId)"
          ><v-icon left color="error">mdi-trash-can-outline</v-icon
          >Remove</v-btn
        >
      </template>
    </dialog-small>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Faqs from '../../../components/ui/Faqs'
import DialogFull from '../../../components/wrapper/DialogFull'
import DialogSmall from '../../../components/wrapper/DialogSmall'
import SectionItem from '../../../components/wrapper/SectionItem'
import WillFormGuardian from '../forms/Guardian'
import mixins from './mixins'

export default {
  name: 'WillGuardians',
  mixins: [mixins],
  components: {
    WillFormGuardian,
    DialogFull,
    DialogSmall,
    SectionItem,
    Faqs,
  },
  data: () => ({
    openForm: null,
    entityId: null,
    processing: false,
    openRemoveGuardians: false
  }),
  methods: {
    close() {
      this.openForm = null
      this.entityId = null
    },
    open(id = null) {
      if (this.accessStatus === 'expired') {
        this.$emit('accessDenied')
        return
      }
      this.entityId = id
      this.openForm = 'guardian'
    },
    remove(id) {
      if (this.accessStatus === 'expired') {
        this.$emit('accessDenied')
        return
      }
      this.entityId = id
      this.openRemoveGuardians = true
    },
    async skip() {
      this.processing = true
      await this.$store.dispatch('account/update_will_progress', ['children'])
      this.$emit('next')
      this.processing = false
    },
    async deleteGuardians(id) {
      if (this.accessStatus === 'expired') {
        this.$emit('accessDenied')
        return
      }

      const person = this.$store.getters['account/entity_person'](id)
      await this.$store.dispatch('account/save_entity_person', {
        id: id,
        person: { ...person, guardians: null }
      })

      this.entityId = null
      this.openRemoveGuardians = false
    }
  },
  computed: {
    ...mapGetters('account', ['entities_guardians']),
    dialogOpen: {
      get() {
        return Boolean(this.openForm)
      },
      set(val) {
        if (!val) {
          this.close()
        }
      }
    },
    dialogTitle() {
      return this.entityId ? 'Edit Child' : 'Add Child'
    }
  }
}
</script>

<style lang="scss"></style>
