<template>
  <div>
    <p>
      Select the people or charities you would like to inherit your residual
      estate.
    </p>
    <faqs
      :faqs="[
        'whatIsEstate',
        'whatIsBeneficiary',
        'leavingSpecificGift',
        'whatIfBeneficiaryUnder18',
      ]"
      class="mb-4"
    />
    <div class="d-flex align-center py-2">
      <h3>Beneficiaries</h3>
      <v-spacer />
      <v-btn
        v-if="step.isStepComplete"
        text
        class="bg-grey-20 radius-4"
        @click="open('estate')"
      >
        <v-icon
          color="primary"
          left
          >mdi-pencil-outline</v-icon
        >Edit
      </v-btn>
    </div>
    <div v-if="beneficiaries.length">
      <section-item
        v-for="item in beneficiaries"
        :key="item.id"
        class="mb-1"
      >
        <div class="font-weight-bold mb-2 text-lg">{{ item.name }}</div>
        <div class="mb-2"><strong>Share:</strong> {{ item.share }}%</div>
        <div v-if="item.backupOption">
          <strong>Backup:</strong>
          {{
            item.backupOption === "newBeneficiaries"
              ? backupBeneficiaries(item.backupEntities).join(", ")
              : backupOptions[item.backupOption]
          }}
        </div>
      </section-item>
    </div>

    <div
      class="text-center"
      v-if="!step.isStepComplete"
    >
      <v-card
        flat
        color="bg-grey-10"
        class="px-4 pb-4"
      >
        <div class="text-no-result">
          You must select at least one beneficiary to continue
        </div>
        <v-btn
          text
          class="bg-white text-capitalize"
          @click="open('estate')"
          x-large
          >Divide your estate<v-icon
            right
            color="primary"
            size="28"
            >mdi-chevron-right</v-icon
          ></v-btn
        >
      </v-card>
    </div>

    <dialog-full
      :open.sync="dialogOpen"
      title="Divide Estate"
      headline="Step 5"
      ref="dialog"
      path="/will/estate"
    >
      <transition
        :name="openForm === 'estate' ? 'window-right' : 'window-left'"
        @enter="scrollTop"
        mode="out-in"
      >
        <component
          v-if="openForm"
          :is="`will-form-${openForm}`"
          v-bind="backupProps"
          @close="close"
          @next="next"
          @top="scrollTop"
        />
      </transition>
    </dialog-full>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

import Faqs from "../../../components/ui/Faqs"
import DialogFull from "../../../components/wrapper/DialogFull"
import SectionItem from "../../../components/wrapper/SectionItem"
import WillFormEstate from "../forms/Estate"
import WillFormEstateBackup from "../forms/EstateBackup"
import mixins from "./mixins"

export default {
  name: "WillEstate",
  mixins: [mixins],
  components: {
    SectionItem,
    Faqs,
    WillFormEstate,
    WillFormEstateBackup,
    DialogFull,
  },
  data: () => ({
    openForm: null,
    backupProps: null,
  }),
  methods: {
    close(openForm = null) {
      this.openForm = openForm
    },
    next() {
      if (this.beneficiaries.some((x) => x.type === "person"))
        this.openForm = "estate-backup"
      else this.close()
    },
    open(key) {
      this.openForm = key
    },
    scrollTop(pos = 0) {
      this.$refs.dialog.scrollTop(pos)
    },
    backupBeneficiaries(backupEntities) {
      return backupEntities.map((k) => {
        const beneficiaryEntity = this.$store.getters["account/entity"](k)
        return `${beneficiaryEntity.name}${
          beneficiaryEntity.type === "charity" ? "(charity)" : ""
        }`
      })
    },
  },
  computed: {
    ...mapGetters("account", ["will_estate"]),
    backupOptions() {
      let options = this.$store.getters["account/list_group"](
        "backupBeneficiaries"
      )
      let obj = {}
      options.forEach((o) => {
        obj[o.key] = o.label
      })
      return obj
    },

    beneficiaries() {
      return Object.keys(this.will_estate.value)
        .map((k) => {
          const beneficiaryEntity = this.$store.getters["account/entity"](k)
          return {
            id: k,
            name: beneficiaryEntity.name,
            type: beneficiaryEntity.type,
            share: this.will_estate.value[k].share,
            ...(beneficiaryEntity.type !== "charity" && {
              backupOption: this.will_estate.value[k].backupOption,
              backupEntities: this.will_estate.value[k].backupEntities,
            }),
          }
        })
        .sort((a, b) => {
          return b.share - a.share
        })
    },
    dialogOpen: {
      get() {
        return Boolean(this.openForm)
      },
      set(val) {
        if (!val) {
          this.close()
        }
      },
    },
  },
}
</script>

<style lang="scss">
.window-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.window-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.window-left-enter-active,
.window-right-enter-active {
  transition: all 0.75s cubic-bezier(0.19, 1, 0.22, 1);
}
</style>
