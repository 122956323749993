<template>
  <div>
    <div class="mb-4">
      <v-text-field
        v-bind="textField"
        label="Charity Name"
        v-model="charity.name"
        :error-messages="
          showErrors.name && errorMessages.name ? errorMessages.name : null
        "
        @input="showErrorsSwitch(true, 'name')"
        @blur="showErrorsSwitch(true, 'name')"
      />
      <v-text-field
        v-bind="textField"
        label="ABN"
        v-model="charity.abn"
        :error-messages="
          showErrors.abn && errorMessages.abn ? errorMessages.abn : null
        "
        @input="showErrorsSwitch(true, 'abn')"
        @blur="showErrorsSwitch(true, 'abn')"
      />
    </div>

    <div class="d-flex align-center">
      <v-btn
        v-bind="backButton"
        @click="close"
      >
        <v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}
      </v-btn>

      <v-spacer />
      <v-btn
        v-bind="buttonType"
        depressed
        @click="submit"
      >
        {{ submitLabel }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "CharityForm",
  props: {
    closeLabel: {
      default: "back",
    },
    submitLabel: {
      default: "save",
    },
    accessStatus: {
      default: null,
    },
  },
  data: () => ({
    charity: {
      name: "",
      abn: "",
    },
    showErrors: {
      first: false,
      middle: false,
      last: false,
      phone: false,
    },
    processing: false,
  }),
  methods: {
    submit() {
      this.processing = true
      if (this.isValid) {
        this.save(this.localFormatted)
        setTimeout(() => {
          this.processing = false
          this.next()
        }, 500)
        return
      }

      this.showErrorsSwitch()
      this.processing = false
    },
    async save(data) {
      this.$emit("select", data)
    },
    close() {
      this.$emit("cancel")
    },
    next() {
      this.$emit("next")
    },
    showErrorsSwitch(show = true, field = null) {
      if (field) {
        this.showErrors[field] = show
        return
      }

      Object.keys(this.showErrors).forEach((key) => {
        this.showErrors[key] = show
      })
    },
    checkABN(str) {
      if (!str) return false

      str = str.replace(/\s+/g, "").replace(/^\s/, "").replace(/\s$/, "")

      if (!/^[1-9]+[0-9]*$/.test(str)) return false

      if (str.length !== 11) return false

      const weighting = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19]
      let sum = 0

      Array.from(str).forEach((char, index) => {
        if (index === 0) sum += (parseInt(char) - 1) * weighting[index]
        else sum += parseInt(char) * weighting[index]
      })

      return sum / 89 && sum % 89 === 0
    },
  },
  computed: {
    buttonType() {
      if (this.processing) {
        return this.btnProcessing
      }

      if (this.isValid) {
        return this.btnActive
      }

      return this.btnInactive
    },
    errorMessages() {
      const msgs = {
        name: null,
        abn: null,
      }

      if (!this.localFormatted.name) {
        msgs.name = "Required field"
      }
      if (!this.localFormatted.abn) {
        msgs.abn = "Required field"
      }

      if (!this.checkABN(this.localFormatted.abn)) {
        msgs.abn = "This is not a valid ABN"
      }

      return msgs
    },
    isValid() {
      return Object.values(this.errorMessages).every((val) => val === null)
    },
    localFormatted() {
      return {
        name: this.charity.name.trim().replace(/\s\s+/g, " "),
        abn: this.charity.abn.replace(/\s+/g, ""),
      }
    },
  },
}
</script>

<style lang="scss"></style>
