<template>
  <div>
    <div v-if="step.isStepComplete">
      <section-item
        @edit="open('funeral-wishes')"
        class="mb-1"
      >
        <div class="font-weight-bold mb-2">How would you like your body to be treated after you have passed?</div>
        <div>{{ will_funeral_wishes.selectedOption.label }}</div>
      </section-item>
    </div>
    <div class="text-center" v-else>
      <v-card flat color="bg-grey-10" class="px-4 pb-4">
        <div class="text-no-result">Your almost finished, just one last question!</div>
        <v-btn
          text
          class="bg-white text-capitalize"
          @click="open('funeral-wishes')"
          x-large
        >Decide My Funeral Wishes<v-icon right color="primary" size="28">mdi-chevron-right</v-icon></v-btn>
      </v-card>
    </div>

    <dialog-full :open.sync="dialogOpen" title="Funeral Wishes" headline="Step 7" path="/will/wishes">
      <component v-if="openForm" :is="`will-form-${openForm}`" @close="close" />
    </dialog-full>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import DialogFull from '../../../components/wrapper/DialogFull'
import SectionItem from '../../../components/wrapper/SectionItem'
import WillFormFuneralWishes from '../forms/FuneralWishes'
import mixins from './mixins'

export default {
  name: 'WillWishes',
  mixins: [mixins],
  components: { SectionItem, DialogFull, WillFormFuneralWishes },
  data: () => ({
    openForm: null,
    wasIncomplete: true,
  }),
  mounted () {
    this.wasIncomplete = !this.step.isStepComplete
  },
  methods: {
    close () {
      if (this.wasIncomplete && this.step.isStepComplete && !this.access.hasPurchasedWill) {
        this.$router.push('/checkout')
      }
      this.openForm = null
    },
    open (key) {
      if (this.accessStatus === 'expired') {
        this.$emit('accessDenied')
        return
      }
      this.openForm = key
    }
  },
  computed: {
    ...mapGetters('account', ['will_funeral_wishes']),
    dialogOpen: {
      get () {
        return Boolean(this.openForm)
      },
      set (val) {
        if (!val) {
          this.close()
        }
      }
    }
  }
}
</script>

<style lang="scss">
</style>
