<template>
  <v-card
    outlined
    class="item-select"
    :class="{ selected: value }"
  >
    <v-list-item
      link
      @click="$emit('select')"
    >
      <transition
        name="fade"
        mode="out-in"
      >
        <v-list-item-avatar
          v-if="loading"
          left
          size="24"
        >
          <loading-icon color="#b682ff" />
        </v-list-item-avatar>
        <v-list-item-avatar
          v-else-if="value"
          left
          color="success"
          size="24"
        >
          <v-icon
            size="16"
            color="white"
            >mdi-check-bold</v-icon
          >
        </v-list-item-avatar>
        <v-list-item-avatar
          v-else
          left
          color="white"
          size="24"
          style="border: 2px solid #d6dce7 !important"
        >
        </v-list-item-avatar>
      </transition>
      <v-list-item-content>
        <div
          class="font-weight-medium"
          :class="titleSize"
        >
          {{ title }}
        </div>
        <div
          v-if="text"
          class="text-sm opacity-60 mt-1"
        >
          {{ text }}
        </div>
      </v-list-item-content>
    </v-list-item>
    <slot></slot>
  </v-card>
</template>

<script>
import LoadingIcon from "./LoadingIcon.vue"

export default {
  name: "ItemSelect",
  props: {
    value: {
      default: false,
    },
    loading: {
      default: false,
    },
    title: {
      default: "Select",
    },
    text: {
      default: null,
    },
    titleSize: {
      default: "text-lg",
    },
  },
  components: { LoadingIcon },
}
</script>

<style lang="scss">
.item-select.selected {
  border-color: #008656;
}
</style>
