<template>
  <drawer :open.sync="localOpen" title="Select Charity" :width="500">
    <v-overlay :value="loading" absolute>
      <v-progress-circular
        class="mx-auto mb-4 d-block"
        indeterminate
        size="32"></v-progress-circular>
      <div class="text-center font-weight-medium white--text">
        Adding Charity
      </div>
    </v-overlay>
    <div v-if="localOpen">
      <v-window v-model="window" touchless>
        <v-window-item value="search">
          <!-- search box and tags -->
          <div>
            <div class="text-center py-4" v-if="tags.length > 0">
              <v-chip
                class="ma-1"
                :color="local.selectedTags === null ? 'primary' : ''"
                @click="local.selectedTags = null"
                >All Charities</v-chip
              >
              <v-chip
                class="ma-1"
                :color="local.selectedTags === tag ? 'primary' : ''"
                v-for="tag in tags"
                :key="tag"
                @click="local.selectedTags = tag"
                >{{ tag }}</v-chip
              >
            </div>
            <div class="px-4 mb-0">
              <v-text-field
                v-model="local.searchTerm"
                placeholder="Search Australian charities"
                outlined
                prepend-inner-icon="mdi-magnify"
                background-color="white"
                clearable />
            </div>
          </div>

          <!-- search results -->
          <charity-search-results
            v-if="searchResults"
            :charities="searchResults"
            @select="addCharity" />
          <div class="pa-4 text-center">
            <div class="w-label mb-4">
              Can't find the charity you are looking for?
            </div>
            <v-btn v-bind="outlineButton" @click="window = 'add'"
              >Add a charity</v-btn
            >
          </div>
          <div class="pb-16 mt-8"></div>
        </v-window-item>
        <v-window-item value="add">
          <v-toolbar flat>
            <v-btn @click="window = 'search'" icon small color="black"
              ><v-icon size="22">mdi-arrow-left</v-icon></v-btn
            >
            <v-toolbar-title class="pl-2">Add Charity</v-toolbar-title>
          </v-toolbar>
          <div class="px-4">
            <v-alert color="#eee" class="mb-4">
              <p>
                Enter the name and ABN of the charity you want to add to your
                Will.
              </p>
              <div>
                For Australian charities names and ABNs, see the
                <a
                  href="https://www.acnc.gov.au/charity/charities"
                  target="_blank"
                  >ACNC database</a
                >.
              </div>
            </v-alert>
            <charity-form
              v-if="window === 'add'"
              @cancel="window = 'search'"
              @select="addCharity" />
          </div>
        </v-window-item>
      </v-window>
    </div>
  </drawer>
</template>

<script>
import Drawer from '../wrapper/Drawer'
import CharityForm from './CharityForm'
import CharitySearchResults from './CharitySearchResults'

export default {
  name: 'CharitySelect',
  components: {
    Drawer,
    CharityForm,
    CharitySearchResults
  },
  props: {
    open: {
      default: false
    }
  },
  data: () => ({
    local: {
      referrers: [],
      selectedTags: null,
      searchTerm: '',
      searchResults: [],
      loading: false
    },
    window: 'search',
    loading: false
  }),
  async mounted() {
    await this.$store.dispatch('app/load_charities')
  },
  methods: {
    addCharity(charity) {
      this.loading = true
      this.$store
        .dispatch('account/save_entity_charity', {
          abn:  charity.abn,
          name: charity.name,
          type: 'charity'
        })
        .then((id) => {
          this.$emit('add', id)
          this.localOpen = false
          this.loading = false
        })
    }
  },
  computed: {
    searchResults() {
      let results = this.$store.getters['app/charities_list_featured'](
        this.userState,
        this.local.searchTerm,
        this.local.selectedTags
      )

      return results
    },
    userState() {
      const address = this.$store.getters['account/user_address'].value
      if (typeof address === 'object') {
        return address.state ? address.state.toLowerCase().trim() : 'default'
      }

      const states = {
        'New South Wales': 'Nsw',
        'Northern Territory': 'Nt',
        Queensland: 'Qld',
        'South Australia': 'Sa',
        Tasmania: 'Tas',
        Victoria: 'Vic',
        'Western Australia': 'Wa',
        'Australian Capital Territory': 'Act'
      }
      const stateCodes = Object.keys(states).concat(Object.values(states))
      let st = null

      const _address = address
        .replace(/[^a-zA-Z\s]/g, '')
        .replace(/[ ]{2,}/g, ' ')
        .toLowerCase()
        .trim()

      for (let i = 0; i < stateCodes.length; i++) {
        if (
          _address.indexOf(` ${stateCodes[i].toLowerCase()} `) > -1 ||
          _address.indexOf(
            ` ${stateCodes[i].replace(/[\s]/g, '').toLowerCase()} `
          ) > -1
        ) {
          if (stateCodes[i].length > 3) {
            stateCodes[i] = states[stateCodes[i]]
          }

          st = stateCodes[i].toLowerCase()
          break
        }
      }
      return st || 'default'
    },
    tags() {
      return this.$store.getters['app/charities_tags']
    },
    localOpen: {
      get() {
        return this.open
      },
      set(val) {
        this.window = 'search'
        this.local.selectedTags = null
        this.local.searchTerm = ''
        this.$emit('update:open', val)
      }
    },
  }
}
</script>
