<template>
  <div id="beneficiaries">
    <div v-if="local">
      <h2 class="mb-4">How would you like to divide your estate?</h2>
      <notification-box
        type="info"
        class="mb-6"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <ul>
            <li>
              Add the people or charities you would like to inherit your estate
            </li>
            <li>Enter the percentage for each beneficiary</li>
            <li>The total percentage must equal 100%</li>
            <li>
              <strong>Do not</strong> enter your backup beneficiaries, you can
              do that in the next step
            </li>
          </ul>
        </div>
      </notification-box>
      <h3
        class="mb-2"
        ref="label"
      >
        Selected Beneficiaries:
      </h3>
      <transition
        name="component-fade"
        mode="out-in"
      >
        <div
          v-if="!local.length"
          class="text-no-result"
          key="empty"
        >
          - Add at least one beneficiary -
        </div>
        <div
          key="list"
          v-else
        >
          <p class="mb-2">
            Enter the percent of each beneficiary to the right. The total must
            equal 100%.
          </p>
          <transition-group
            name="component-fade"
            mode="out-in"
          >
            <item-person
              v-for="(item, index) in local"
              :key="`entity-${index}`"
              class="mb-4"
              :id="item.id"
              @remove="removeBeneficiary(index)"
            >
              <template v-slot:append>
                <v-list-item-action class="share-input">
                  <v-text-field
                    hide-details
                    solo
                    flat
                    prefix="%"
                    reverse
                    min="0"
                    max="100"
                    type="number"
                    step=".01"
                    v-model="item.data.share"
                  ></v-text-field>
                </v-list-item-action>
              </template>
            </item-person>
          </transition-group>
        </div>
      </transition>

      <v-list-item class="pr-0 mb-4">
        <v-list-item-content> </v-list-item-content>
        <v-list-item-action class="ma-0 pa-0 text-center">
          <v-card
            flat
            class="px-8 py-2 bg-grey-20"
          >
            <div class="text-label opacity-60 text-sm">Total</div>
            <h3>{{ totalShare }}%</h3>
          </v-card>
        </v-list-item-action>
      </v-list-item>

      <div class="text-label mb-4">Add a beneficiary:</div>
      <div class="mb-2">
        <btn-add-large
          label="Add person"
          class="mb-2"
          @click="openDrawerPerson = true"
        />
        <template v-if="referrer_beneficiary_doc">
          <btn-add-large
            :label="`Add ${referrer_beneficiary_doc.name}`"
            subtitle="Featured Charity"
            class="mt-2"
            :img="
              referrer_beneficiary_doc.logo
                ? referrer_beneficiary_doc.logo.icon
                : null
            "
            @click="addCharity(referrer_beneficiary_doc)"
            :disabled="isReferrerSelected"
          />
          <btn-add-large
            @click="openDrawerCharity = true"
            label="Add Charity"
            class="mt-2"
          />
        </template>
        <template v-else>
          <charities-featured
            class="mt-4"
            @add="(id) => addBeneficiary(id, true)"
          >
            <template v-slot:footer>
              <div class="text-center">
                <v-btn
                  text
                  class="bg-grey"
                  @click="openDrawerCharity = true"
                >
                  <v-icon
                    left
                    color="primary"
                    >mdi-magnify</v-icon
                  >
                  View more
                </v-btn>
              </div>
            </template>
          </charities-featured>
        </template>
      </div>
      <v-input
        class="error-right"
        :error-messages="
          showErrors.form && errorMessages.form ? errorMessages.form : null
        "
      />

      <transition
        name="component-fade"
        mode="out-in"
      >
        <div class="my-4">
          <charity-share-data
            v-if="showCharityOptOut"
            :value.sync="shareData"
          />
        </div>
      </transition>
      <div class="d-flex align-center">
        <v-btn
          text
          @click="close"
          >{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >Save</v-btn
        >
      </div>
    </div>

    <drawer
      :open.sync="openDrawerPerson"
      title="Add Beneficiary"
    >
      <drawer-person-select
        v-if="openDrawerPerson"
        @select="addBeneficiary"
        :exclude="selectedIds"
      />
    </drawer>
    <charity-select
      :open.sync="openDrawerCharity"
      title="Select Charity"
      @add="addBeneficiary"
    />
  </div>
</template>

<script>
import { cloneDeep, isEqual, toNumber } from "lodash"
import { mapGetters } from "vuex"

import CharitiesFeatured from "../../../components/charities/CharitiesFeatured"
import CharitySelect from "../../../components/charities/CharitySelect"
import CharityShareData from "../../../components/charities/CharityShareData"
import BtnAddLarge from "../../../components/ui/BtnAddLarge"
import NotificationBox from "../../../components/ui/NotificationBox"
import Drawer from "../../../components/wrapper/Drawer"
import DrawerPersonSelect from "../../../components/wrapper/DrawerPersonSelect"
import ItemPerson from "../../../components/wrapper/ItemPerson"

export default {
  name: "WillFormEstate",
  components: {
    NotificationBox,
    CharitySelect,
    CharitiesFeatured,
    CharityShareData,
    ItemPerson,
    BtnAddLarge,
    DrawerPersonSelect,
    Drawer,
  },
  props: {
    closeLabel: {
      default: "close",
    },
  },
  data: () => ({
    local: null,
    shareData: true,
    showErrors: {
      form: false,
    },
    processing: false,
    openDrawerPerson: false,
    openDrawerCharity: false,
  }),
  mounted() {
    let estate = cloneDeep(this.will_estate.value)
    this.local = Object.keys(estate)
      .map((k) => {
        return {
          id: k,
          data: estate[k],
        }
      })
      .sort((a, b) => {
        return b.data.share - a.data.share
      })
  },
  methods: {
    async submit() {
      this.processing = true
      if (this.isValid) {
        if (this.isChanged) {
          await this.save()
        }
        if (this.showCharityOptOut) {
          await this.$store.dispatch("account/save_user_data_deep", {
            meta: {
              shareWithCharities: this.shareData,
              shareWithCharitiesIncludesDob: true,
            },
          })
        }
        setTimeout(() => {
          this.processing = false
          this.next()
        }, 500)
        return
      }

      this.showErrorsSwitch()
      this.processing = false
    },
    async save() {
      await this.$store.dispatch("account/save_user_data", {
        beneficiaries: this.localFormatted,
      })
      await this.$store.dispatch("account/update_will_progress", ["estate"])
    },
    close() {
      this.$emit("close")
    },
    next() {
      this.$emit("next")
    },
    showErrorsSwitch(show = true, field = null) {
      if (field) {
        this.showErrors[field] = show
        return
      }

      Object.keys(this.showErrors).forEach((key) => {
        this.showErrors[key] = show
      })
    },
    addBeneficiary(id, isCharity = false) {
      if (!this.selectedIds.includes(id)) {
        this.local.push({
          id: id,
          data: this.will_estate.value[id] || {
            share: 0,
            backupOption: isCharity ? "" : "theirChildren",
            backupEntities: [],
          },
        })
      }
      this.openDrawerPerson = false
      this.openDrawerCharity = false
      let pos = this.$refs.label.getBoundingClientRect().top
      this.$emit("top", pos)
    },

    async addCharity(charity) {
      let id = await this.$store.dispatch("account/save_entity_charity", {
        abn: Number(charity.abn),
        name: charity.name.trim(),
        type: "charity",
      })

      this.addBeneficiary(id, true)
    },
    removeBeneficiary(index) {
      this.$delete(this.local, index)
    },
  },
  computed: {
    ...mapGetters("account", ["will_estate"]),
    ...mapGetters("app", ["referrer_beneficiary_doc"]),
    ...mapGetters("account", ["user_meta_data"]),
    selectedIds() {
      return this.local.map((o) => o.id)
    },
    isReferrerSelected() {
      let charities = this.$store.getters["account/entities_charities"]
        .filter((o) => {
          return Number(o.abn) === Number(this.referrer_beneficiary_doc.abn)
        })
        .map((o) => {
          return o.id
        })

      let intersection = charities.filter((x) => this.selectedIds.includes(x))

      return Boolean(intersection.length)
    },
    buttonType() {
      if (this.processing) {
        return this.btnProcessing
      }

      if (this.isValid) {
        return this.btnActive
      }

      return this.btnInactive
    },
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })

      if (this.totalShare !== 100) {
        msgs.form = "Total must equal 100%. Scroll up to update percentages."
      }

      if (
        this.local.some((o) => {
          return o.data.share < 0
        })
      ) {
        msgs.form = "Percentages must be greater than zero"
      }

      return msgs
    },
    isValid() {
      return Object.values(this.errorMessages).every((val) => val === null)
    },
    isChanged() {
      return !isEqual(this.localFormatted, this.will_estate.value)
    },
    localFormatted() {
      let estate = {}
      this.local.forEach((o) => {
        o.data.share = Number(o.data.share)
        if (o.data.share !== 0) {
          estate[o.id] = {
            ...o.data,
          }
        }
      })

      return estate
    },
    totalShare() {
      return this.local.reduce((sum, b) => {
        let n = parseFloat(toNumber(b.data.share).toFixed(2))
        return parseFloat((sum + n).toFixed(10))
      }, 0)
    },
    showCharityOptOut() {
      if (
        Object.keys(this.localFormatted).length > 0 &&
        typeof this.user_meta_data.shareWithCharities === "undefined"
      ) {
        let charitySelected = false
        Object.keys(this.localFormatted).forEach((id) => {
          const entity = this.$store.getters["account/entity"](id)
          if (entity.type === "charity") charitySelected = true
        })
        return charitySelected
      }
      return false
    },
  },
}
</script>

<style lang="scss">
.share-input {
  border-left: 1px solid var(--grey);
  width: 100px;
  margin: 0;
  padding: 0;
  min-height: 64px;
  align-self: stretch;
  .v-input {
    height: 100%;
    .v-input__control {
      height: 100%;
      .v-input__slot {
        background-color: var(--grey-10);
        height: 100%;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        font-size: 18px;
        padding-left: 0px !important;
      }
    }
  }
}
.v-input.error-right {
  .v-messages__message {
    text-align: right;
  }
}
</style>
