<template>
  <v-chip small color="white" class="font-weight-bold" @click="$emit('click')"
    ><v-icon left color="warning">mdi-alert-circle</v-icon>{{ text }}</v-chip
  >
</template>

<script>
export default {
  name: 'ChipRequired',
  props: {
    text: {
      default: 'Required'
    }
  },
  data: () => ({})
}
</script>

<style lang="scss"></style>
