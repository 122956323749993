var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.local)?_c('div',[_c('h2',{staticClass:"mb-2"},[_vm._v("Your pet's name")]),_c('v-text-field',_vm._b({attrs:{"error-messages":_vm.showErrors.name && _vm.errorMessages.name ? _vm.errorMessages.name : null},on:{"input":function($event){return _vm.showErrorsSwitch(true, 'name')},"blur":function($event){return _vm.showErrorsSwitch(true, 'name')}},model:{value:(_vm.local.name),callback:function ($$v) {_vm.$set(_vm.local, "name", $$v)},expression:"local.name"}},'v-text-field',_vm.textField,false)),_c('h2',{staticClass:"mb-4"},[_vm._v("What type of animal is this pet?")]),_c('v-text-field',_vm._b({attrs:{"placeholder":"eg. dog, cat, iguana, etc","error-messages":_vm.showErrors.petType && _vm.errorMessages.petType
          ? _vm.errorMessages.petType
          : null},on:{"input":function($event){return _vm.showErrorsSwitch(true, 'petType')},"blur":function($event){return _vm.showErrorsSwitch(true, 'petType')}},model:{value:(_vm.local.petType),callback:function ($$v) {_vm.$set(_vm.local, "petType", $$v)},expression:"local.petType"}},'v-text-field',_vm.textField,false)),_c('div',{staticClass:"mb-2"},[_c('h2',{staticClass:"mb-4"},[_vm._v("Who should be this pet's guardian?")]),_c('p',[_vm._v("Select the person you would like to leave this pet to.")]),_c('transition',{attrs:{"name":"component-fade","mode":"out-in"}},[(_vm.localFormatted.guardian)?_c('item-person',{key:_vm.localFormatted.guardian,staticClass:"mb-4",attrs:{"id":_vm.localFormatted.guardian}}):_vm._e()],1),_c('btn-add-large',{attrs:{"label":_vm.buttonLabel,"icon":_vm.buttonIcon},on:{"click":function($event){_vm.openDrawer = true}}}),_c('v-input',{attrs:{"error-messages":_vm.showErrors.guardian && _vm.errorMessages.guardian
            ? _vm.errorMessages.guardian
            : null}})],1),_c('h2',{staticClass:"mb-4"},[_vm._v("Would you like to leave a gift for maintenance of this pet?")]),_c('p',[_vm._v(" If you would like to leave a sum of money to the person nominated to care for your pet, on the condition that that person does indeed care for your pet, enter the dollar amount here. ")]),_c('v-text-field',_vm._b({directives:[{name:"money",rawName:"v-money",value:(_vm.moneyFormat),expression:"moneyFormat"}],attrs:{"error-messages":_vm.showErrors.maintenance && _vm.errorMessages.maintenance
          ? _vm.errorMessages.maintenance
          : null},model:{value:(_vm.local.maintenance),callback:function ($$v) {_vm.$set(_vm.local, "maintenance", $$v)},expression:"local.maintenance"}},'v-text-field',_vm.textField,false)),_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',_vm._b({on:{"click":_vm.close}},'v-btn',_vm.backButton,false),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-left")]),_vm._v(_vm._s(_vm.closeLabel))],1),_c('v-spacer'),_c('v-btn',_vm._b({attrs:{"depressed":""},on:{"click":_vm.submit}},'v-btn',_vm.buttonType,false),[_vm._v("Save")])],1)],1):_vm._e(),_c('drawer',{attrs:{"open":_vm.openDrawer,"title":"Add Guardian"},on:{"update:open":function($event){_vm.openDrawer=$event}}},[(_vm.openDrawer)?_c('drawer-person-select',{attrs:{"exclude":[_vm.localFormatted.guardian]},on:{"select":_vm.addGuardian}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }