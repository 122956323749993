<template>
  <v-container>
    <div v-if="charities.length === 0" class="text-center no-result">
      There are no charities matching your search.
    </div>
    <v-row v-else justify="center" align-content="center">
      <div class="col-6" v-for="(charity, index) in charities" :key="index">
        <div class="charity-box" v-ripple>
          <div
            class="charity-logo text-center pa-1"
            @click="selectCharity(charity)">
            <v-img
              height="68px"
              contain
              lazy-src="@/assets/images/loading-rolling.svg"
              :src="charity.logo.default"
              :title="charity.name"
              :aspect-ratio="2"
              v-if="charity.logo && charity.logo.default"></v-img>
            <div
              class="text-primary d-flex w-[100%] h-[100px] items-center justify-center"
              v-else>
              <h1 class="h-[68px]">
                {{
                  charity.name
                    .split(' ')
                    .map((x) => x.substring(0, 1))
                    .join('')
                    .toUpperCase()
                }}
              </h1>
            </div>
          </div>
          <div
            class="item-label d-flex justify-space-between flex-nowrap"
            :title="charity.name">
            <div @click="selectCharity(charity)" class="flex-grow-1">
              {{ charity.name }}
            </div>
            <v-btn
              icon
              x-small
              v-if="charity.settings.showSummary && charity.summary"
              @click="sumCharity = charity">
              <v-icon>mdi-information-outline</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </v-row>
    <v-dialog
      persistent
      max-width="520px"
      v-model="sumCharity"
      v-if="sumCharity"
      content-class="info-dialog">
      <v-card class="dialog-form">
        <v-card-title class="w-title d-flex justify-space-between flex-nowrap">
          <div>
            {{ sumCharity.name }}
          </div>
          <v-btn icon @click="sumCharity = null">
            <v-icon color="black">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <perfect-scrollbar class="px-6 py-3" v-html="sumCharity.summary">
        </perfect-scrollbar>
        <v-card-actions class="actions d-flex justify-end align-end px-6 pb-4">
          <v-btn v-bind="outlineButton" @click="sumCharity = null">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: 'CharitySearchResults',
  props: ['charities'],
  data: () => ({
    sumCharity: null
  }),
  methods: {
    selectCharity(charity) {
      this.$emit('select', charity)
    }
  }
}
</script>

<style scoped lang="scss">
.charity-partner-item {
  margin: 0 auto 1rem;
  width: 50%;
  min-height: 200px;

  &:hover {
    opacity: 0.8;
  }
}

.w-title > :first-child {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: CooperBT;
}

.charity-wrapper {
  border: 2px solid #d6dce7;
  border-radius: 6px;
  margin: 0;
  justify-content: center !important;
}

.charity-box {
  border: 2px solid #d6dce7;
  border-radius: 6px;
  background-color: rgba(255, 255, 255);
  cursor: pointer;
  .charity-logo {
    position: relative;
  }
  &:hover .charity-logo:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.05);
  }

  img,
  h1 {
    padding: 0.1rem;
    margin: 6px;
    height: 56px;
    max-width: calc(100% - (0.2rem * 2));
    font-family: CooperBT;
  }

  .item-label {
    width: 100%;
    font-weight: 600;
    color: #000;
    font-size: 0.8rem;
    background-color: #d6dce7;
    padding: 0.5rem;

    & > :first-child {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
