<template>
  <div>
    <p>If you want to, you can leave gifts to family members, friends or charities. A gift is a specific asset, collection of items or sum of money. For example,  a car, a family heirloom, artwork or shares.</p>
    <p>Gifts are deducted from your estate before it is divided amongst the beneficiaries you previously nominated in the Divide Estate section.</p>
    <div class="d-flex align-center py-2">
      <h3>Your Gifts</h3>
      <v-spacer />
      <v-btn
        text
        class="bg-grey-20 radius-4"
        @click="open()"
      ><v-icon color="primary" left>mdi-plus-thick</v-icon>Add</v-btn>
    </div>

    <transition name="component-fade" mode="out-in">
      <v-card
        flat
        color="bg-grey-10"
        v-if="entities_gifts.length === 0"
        class="py-4"
      >
        <div class="results-empty" key="empty">
          <div>To add a gift click the <strong>'Add'</strong> button on the right</div>
        </div>
        <div class="text-center" v-if="!step.isStepComplete || processing">
          <div class="text-label">Don't want to leave a gift?</div>
          <v-btn
            :loading="processing"
            class="bg-white text-capitalize"
            text
            x-large
            @click="skip()"
          >Skip this Step<v-icon right color="primary" size="28">mdi-chevron-right</v-icon></v-btn>
        </div>
      </v-card>
      <div v-else key="list">
        <transition-group name="component-fade" mode="out-in">
          <section-item
            v-for="item in entities_gifts"
            class="mb-1"
            :key="item.id"
            @edit="open(item.id)"
            @delete="deleteEntity(item.id)"
          >
            <div class="font-weight-bold mb-2 text-lg">{{ item.item }}</div>
            <div><strong>To:</strong> {{ $store.getters['account/entity'](item.recipient).name }}</div>
          </section-item>
        </transition-group>
      </div>
    </transition>

    <dialog-full :open.sync="dialogOpen" :title="dialogTitle" headline="Step 6" path="/will/gifts">
      <component
        v-if="openForm"
        :is="`will-form-${openForm}`"
        @close="close"
        @next="close"
        :id="entityId"
        close-label="Cancel"
      />
    </dialog-full>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Faqs from '../../../components/ui/Faqs'
import DialogFull from '../../../components/wrapper/DialogFull'
import SectionItem from '../../../components/wrapper/SectionItem'
import WillFormGift from '../forms/Gift'
import mixins from './mixins'

export default {
  name: 'WillGifts',
  mixins: [mixins],
  components: { SectionItem, Faqs, WillFormGift, DialogFull },
  data: () => ({
    openForm: null,
    entityId: null,
    processing: false,
  }),
  methods: {
    close () {
      this.openForm = null
      this.entityId = null
    },
    async skip() {
      this.processing = true
      await this.$store.dispatch('account/update_will_progress', ['gifts'])
      this.$emit('next')
      this.processing = false
    },
    open (id = null) {
      if (this.accessStatus === 'expired') {
        this.$emit('accessDenied')
        return
      }
      this.entityId = id
      this.openForm = 'gift'
    },
    async deleteEntity(id) {
      if (this.accessStatus === 'expired') {
        this.$emit('accessDenied')
        return
      }
      await this.$store.dispatch('account/delete_entity', id)
    }
  },
  computed: {
    ...mapGetters('account', ['entities_gifts']),
    dialogOpen: {
      get () {
        return Boolean(this.openForm)
      },
      set (val) {
        if (!val) {
          this.close()
        }
      }
    },
    dialogTitle () {
      return this.entityId ? 'Edit Gift' : 'Add Gift'
    }
  }
}
</script>

<style lang="scss">
</style>
