<template>
  <div>
    <div v-if="local">
      <div>
        <notification-box type="info" class="mb-6" :flat="true">
          <div class="text-label">Instructions</div>
          <ul>
            <li>Add one of your children</li>
            <li>Add 1 guardian or 2 joint guardians</li>
            <li>Guardians must be over 18 years old</li>
          </ul>
        </notification-box>
        <h2 class="mb-4">Your Child</h2>
        <p>
          Enter the details of one child, then nominate a guardian for them. After saving repeat this process for each of your children.
        </p>
        <transition name="component-fade" mode="out-in">
          <item-person v-if="id" :key="id" :id="id" class="mb-4" />
        </transition>

        <btn-add-large
          @click="openDrawerChild = true"
          label="Add Child"
          v-if="!id"
        />
        <v-input
          :error-messages="
            showErrors.child && errorMessages.child ? errorMessages.child : null
          "
        />
      </div>
      <div class="mb-4">
        <h2 class="mb-4">Who should be the guardian(s) for this child?</h2>
        <p>Select who you would like to be the guardians of this child if you and the other parent of the child were both deceased.</p>
        <p><strong>Important:</strong> Do not nominate the other parent of the child.</p>
        <faqs :faqs="['whatGuardian', 'whoGuardian']" class="mb-4" />
        <transition-group name="component-fade" mode="out-in">
          <item-person
            v-for="(g, index) in local.guardians"
            :key="g"
            :id="g"
            class="mb-4"
            @remove="removeGuardian(index)"
          />
        </transition-group>

        <btn-add-large
          @click="openDrawerGuardian = true"
          label="Add Guardian"
          @clickDisabled="disabledMessage()"
          :disabled="isMaximumSelected"
        />
        <v-input
          :error-messages="
            showErrors.guardians && errorMessages.guardians
              ? errorMessages.guardians
              : null
          "
        />
      </div>

      <div class="d-flex align-center">
        <v-btn v-bind="backButton" @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn v-bind="buttonType" depressed @click="submit">Save</v-btn>
      </div>
    </div>
    <drawer :open.sync="openDrawerGuardian" title="Add Guardian">
      <drawer-person-select
        v-if="openDrawerGuardian"
        @select="addGuardian"
        :exclude="excludeGuardianPersonList"
      />
    </drawer>
    <drawer :open.sync="openDrawerChild" title="Select Child">
      <drawer-person-select
        v-if="openDrawerChild"
        @select="selectChild"
        :exclude="excludeChildPersonList"
      />
    </drawer>
  </div>
</template>

<script>
import { cloneDeep, isEqual } from 'lodash'

import BtnAddLarge from '../../../components/ui/BtnAddLarge'
import Faqs from '../../../components/ui/Faqs'
import NotificationBox from '../../../components/ui/NotificationBox'
import Drawer from '../../../components/wrapper/Drawer'
import DrawerPersonSelect from '../../../components/wrapper/DrawerPersonSelect'
import ItemPerson from '../../../components/wrapper/ItemPerson'

export default {
  name: 'WillFormGuardian',
  components: { Faqs, NotificationBox, BtnAddLarge, Drawer, ItemPerson, DrawerPersonSelect },
  props: {
    closeLabel: {
      default: 'close'
    },
    id: {
      default: null
    }
  },
  data: () => ({
    local: null,
    showErrors: {
      child: false,
      guardians: false
    },
    processing: false,
    openDrawerGuardian: false,
    openDrawerChild: false
  }),
  mounted() {
    this.local = cloneDeep(this.entity_child_ua)
  },
  methods: {
    async submit() {
      this.processing = true
      if (this.isValid) {
        if (this.isChanged) {
          await this.save()
        }
        setTimeout(() => {
          this.next()
        }, 500)
        return
      }

      this.showErrorsSwitch()
      this.processing = false
    },
    async save() {
      await this.$store.dispatch('account/save_entity_person', {
        id: this.id,
        person: this.localFormatted
      })
      await this.$store.dispatch('account/update_will_progress', ['children'])
    },
    close() {
      this.$emit('close')
    },
    next() {
      this.$emit('next')
    },
    showErrorsSwitch(show = true, field = null) {
      if (field) {
        this.showErrors[field] = show
        return
      }

      Object.keys(this.showErrors).forEach((key) => {
        this.showErrors[key] = show
      })
    },
    addGuardian(id) {
      this.local.guardians.push(id)
      this.openDrawerGuardian = false
    },
    selectChild(id) {
      this.$emit('update:id', id)
      this.openDrawerChild = false
    },
    removeGuardian(index) {
      this.$delete(this.local.guardians, index)
    },
    disabledMessage() {
      if (this.isMaximumSelected) {
        this.$store.commit('app/SET_MESSAGE', {
          type: null,
          text: 'Maximum of 2 guardians'
        })
      }
    }
  },
  computed: {
    entity_child_ua() {
      const person = this.$store.getters['account/entity_person'](this.id)
      return {
        guardians: person.guardians || []
      }
    },
    excludeChildPersonList() {
      return [
        ...this.$store.getters['account/will_executors'].value.primary,
        ...this.$store.getters['account/will_executors'].value.backup,
        this.$store.getters['account/user_partner'].value.partnerId,
        ...this.$store.getters['account/entities_people'].flatMap(
          (x) => x.guardians
        ),

        ...this.$store.getters['account/entities_people']
          .filter((x) => x.guardians && x.guardians.length > 0)
          .map((x) => x.id),
        ...this.local.guardians
      ]
    },
    excludeGuardianPersonList() {
      return [
        ...this.local.guardians,
        ...this.$store.getters['account/entities_people']
          .filter(
            (x) =>
              Boolean(x.guardians && x.guardians.length > 0)
          )
          .map((x) => x.id),
        this.id
      ]
    },
    isMaximumSelected() {
      return this.localFormatted.guardians.length >= 2
    },
    buttonType() {
      if (this.processing) {
        return this.btnProcessing
      }

      if (this.isValid) {
        return this.btnActive
      }

      return this.btnInactive
    },
    errorMessages() {
      const msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })

      if (!this.id) {
        msgs.child = 'Required'
      }

      if (!this.localFormatted.guardians.length) {
        msgs.guardians = 'Required'
      }

      return msgs
    },
    isValid() {
      return Object.values(this.errorMessages).every((val) => val === null)
    },
    isChanged() {
      return !isEqual(this.localFormatted, this.entity_child_ua)
    },
    localFormatted() {
      return {
        guardians: this.local.guardians || []
      }
    }
  }
}
</script>

<style lang="scss"></style>
